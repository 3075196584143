@tailwind base;
@tailwind components;
@tailwind utilities;

.body * {
  box-sizing: border-box;
}

.loading-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
}

.loading-animation svg {
  width: 300px;
  height: 300px;
}

.floating-element {
  position: relative;
  display: inline-block;
  padding: 20px 40px;
  background-color: #ffffff;
  max-width: 100vw;
  width: 400px;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  color: #333;

  /* Create the shadow effect with a radial gradient */
  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 150%;
    max-width: 100vw;
    height: 30px; /* Short height for subtle shadow */
    background: radial-gradient(
      ellipse,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 70%
    );
    border-radius: 50%;
    z-index: -1;

    @media screen and (max-width: 550px) {
      width: 80%;
    }
  }
}
